.navbar {
    transition: 0.3s;
    background: transparent;
    background: #ffffff;
    padding: 0;
    border-bottom: 1px solid var(--border-brutal);
    // &.scroll{
    // }
    .container-fluid{
        padding: 0;
    }
    .navbar-brand{
        padding: 0 16px;
        border-right: 1px solid var(--border-brutal); 
        align-self: stretch;
        display: flex;
        align-items: center;
    }
    .navbar-nav {
        padding: 20px 0;
        .nav-link {
            font-size: 15px;
            color: var(--color-1);
            border-radius: 4px;
            padding: 0.25rem 0.5rem;
            margin: 0 5px;
            font-weight: 500;
            &:hover {
                background: rgba(0, 0, 0, 0.1);
                color: var(--color-1);
            }
            &:active {
                background: rgba(0, 0, 0, 0.2);
                color: var(--color-2);
            }
            @media (max-width: 991.98px) {
                margin: 5px 0;
            }
        }

        .dropdown {
            .dropdown-menu {
                display: block;
                visibility: hidden;
                transition: 0.3s;
                opacity: 0;
                padding: 6px;
                border: 0;
                border-radius: 8px;
                box-shadow: 0 0 0 1px var(--border-brutal), 4px 4px 0 var(--border-brutal);
                transform: scale(0.95) translateY(10px);

                .dropdown-item {
                    padding: 4px 8px;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 14px;
                    &:hover {
                        background: rgba(0, 0, 0, 0.07);
                    }
                    &:focus{
                        color: #000;
                    }
                }
            }

            .dropdown-toggle {
                cursor: pointer;
                &::after {
                    display: none;
                }
            }

            &:hover .dropdown-menu {
                visibility: visible;
                opacity: 1;
                transform: scale(1) translateY(0);
            }

            @media (max-width: 991.98px) {
                .dropdown-menu {
                    background: transparent;
                    display: none;
                    .dropdown-item {
                        color: var(--color-2);
                        &.active{
                            background: transparent;
                        }
                        &:hover{
                            background: rgba(255, 255, 255, 0.1);
                        }
                    }
                }
                &:hover .dropdown-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: scale(1);
                    display: block;
                }
            }
        }
    }
    .navbar-cta{
        background: var(--color-primary);
        color: #ffffff;
        text-decoration: none;
        font-weight: 500;
        align-self: stretch;
        padding: 0 16px;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--border-brutal);
        transition: 0.3s;
        &:hover{
            filter: brightness(0.85);
        }
        &:active{filter: brightness(0.75);}
    }
    @media (max-width: 991.98px) {
        .navbar-cta {
            margin-left: auto;
            margin-right: 16px;
            padding: 16px;
            margin-right: 0;
        }
    }
    .navbar-toggler {
        padding: 8px;
        border: 0;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
        border-radius: 50%;
        line-height: 0;
        background-color: rgba(255, 255, 255, 0.05);
        color: var(--color-1);

        @media (max-width: 991.98px) {
            order: 2;
            margin: 0 8px;
        }
    }

    .navbar-collapse {
        @media (max-width: 991.98px) {
            order: 3;
        }
    }
}